import { useState, useContext, useEffect } from "react";
import AuthContext from "../utils/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import {
  Container,
  Form,
  Button,
  Card,
  FormGroup,
  FormControl,
  Row,
  Col,
  Image
} from "react-bootstrap";
import jwt_decode from "jwt-decode";
import { uniqueNamesGenerator, adjectives, colors, animals } from 'unique-names-generator';

const loadGoogleApi = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });
};

const Register = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [email, setEmail] = useState("");
  const { registerUser, setAuthTokens, setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isRegistered = await registerUser(username, password, password2, email);
    if (isRegistered) {
      navigate("/login", { state: { username } });
    }
  };

  const handleGoogleSignIn = async (response) => {
    const token = response.credential;
    const apiResponse = await fetch("https://api.doctrina.ai/members/google-login/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ token })
    });
    const data = await apiResponse.json();
    if (apiResponse.ok) {
      setAuthTokens(data);
      setUser(jwt_decode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));
      window.scrollTo(0, 0); 
      navigate("/");
    } else {
      console.error(data.error);
    }
  };

  const generateUsername = () => {
    const randomUsername = uniqueNamesGenerator({
      dictionaries: [adjectives, colors, animals],
      separator: '-',
      length: 3
    });
    setUsername(randomUsername);
  };

  useEffect(() => {
    loadGoogleApi().then(() => {
      if (window.google) {
        window.google.accounts.id.initialize({
          client_id: '1098289877563-s6vrn3li4isjr6j8hfeqtafidcfrbdrg.apps.googleusercontent.com',
          callback: handleGoogleSignIn
        });
        window.google.accounts.id.renderButton(
          document.getElementById('google-signin'),
          { theme: 'outline', size: 'large' }
        );
      }
    }).catch((error) => {
      console.error("Failed to load Google API", error);
    });
  }, []);

  return (
    <Container fluid style={{ backgroundColor: "#fff", minHeight: "100vh", display: "flex", flexDirection: "column", justifyContent: "center" }}>
      <Row className="justify-content-md-center pt-3">
        <Col md={6} className="text-center">
          <Link to="https://doctrina.ai" target="_blank" rel="noopener noreferrer">
            <Image className="logo mt-5 mb-5 login-logo" loading="lazy" fluid src="https://i.ibb.co/C7ykCPj/logo-white.png" />
          </Link>
        </Col>
      </Row>
      <Row className="justify-content-md-center pt-3">
        <Col md={6}>
          <Card>
            <Card.Body>
              <h1 className="text-center mb-3 form-auth">Create free account</h1>
              <h6 className="text-center form-auth mb-3">Join over one million students and educators already using Doctrina and enjoy free AI features</h6>
              <Form className="form-auth" onSubmit={handleSubmit}>
                <FormGroup controlId="formBasicEmail" className="mt-5 register-form">
                  <FormControl
                    type="text"
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                  />
                </FormGroup>
                <FormGroup controlId="formBasicUsername" className="mt-5">
                  <Row>
                    <Col xs={8} sm={9}>
                      <FormControl
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Enter your username or"
                        required
                      />
                    </Col>
                    <Col xs={4} sm={3}>
                      <Button variant="secondary" onClick={generateUsername} className="d-flex w-100">Generate</Button>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="formBasicPassword" className="mt-5">
                  <FormControl
                    type="password"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                    required
                  />
                </FormGroup>
                <FormGroup controlId="formBasicConfirmPassword" className="mt-5">
                  <FormControl
                    type="password"
                    id="confirm-password"
                    onChange={(e) => setPassword2(e.target.value)}
                    placeholder="Confirm your password"
                    required
                  />
                  {password2 !== password && (
                    <p style={{ color: "red", marginTop: "0.5rem" }}>
                      Passwords do not match
                    </p>
                  )}
                </FormGroup>
                <Button
                  variant="primary"
                  type="submit"
                  className="w-50 mt-5 login-button"
                >
                  Sign Up
                </Button>
                <h6 className="text-center mt-5 pb-3">or register with email</h6>
                <Container id="google-signin" className="d-flex justify-content-center pb-3"></Container>
                <p className="pt-5">
                Forgot your password? <Link className="switch-links pt-3" to="/forgot-password">Reset it here</Link>
                </p>
                <p className="pt-3 already-account">Already have an account? <Link className="switch-links" to="/login">Log in</Link></p>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-md-center pt-3 pb-3">
        <Col md={6} className="text-center">
        <ul className="list-unstyled" style={{ padding: '0', margin: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <li style={{ paddingRight: '10px' }}>
            <Link to="https://doctrina.ai/privacy.html" target="_blank" rel="noopener noreferrer">Privacy policy</Link>
          </li>
          <li>
            <span>|</span>
          </li>
          <li style={{ paddingLeft: '10px' }}>
            <Link to="https://doctrina.ai/terms.html" target="_blank" rel="noopener noreferrer">Terms & Conditions</Link>
          </li>
        </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default Register;
