import { useState } from "react";
import { Container, Form, Button, FormGroup, FormControl, Row, Col, Card, Image } from "react-bootstrap";
import { useNavigate, useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const { uidb64, token } = useParams(); 
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword.length > 0) {
      const response = await fetch("https://api.doctrina.ai/members/change-password/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uidb64, token, new_password: newPassword }),
      });

      if (response.status === 200) {
        toast.success("Your password has been changed successfully");
        navigate("/login");
      } else {
        toast.error("Error changing the password");
      }
    }
  };

  return (
    <Container fluid style={{ backgroundColor: "#fff", minHeight: "100vh", display: "flex", flexDirection: "column", justifyContent: "center" }}>
      <Row className="justify-content-md-center pt-3">
        <Col md={6} className="text-center">
          <Link to="https://doctrina.ai" target="_blank" rel="noopener noreferrer">
            <Image className="logo mt-5 mb-5 login-logo" loading="lazy" fluid src="https://i.ibb.co/C7ykCPj/logo-white.png" />
          </Link>
        </Col>
      </Row>
      <Row className="justify-content-md-center pt-3">
        <Col md={6}>
          <Card>
            <Card.Body>
              <h1 className="text-center mb-3">Change Password</h1>
              <h6 className="text-center mb-3">Enter your new password</h6>
              <Form onSubmit={handleSubmit}>
                <FormGroup controlId="formBasicPassword" className="mt-5">
                  <FormControl
                    type="password"
                    id="newPassword"
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Enter your new password"
                    required
                  />
                </FormGroup>
                <Button
                  variant="primary"
                  type="submit"
                  className="w-50 mt-5 login-button"
                >
                  Change Password
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-md-center pt-3 pb-3">
        <Col md={6} className="text-center">
        <ul className="list-unstyled" style={{ padding: '0', margin: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <li style={{ paddingRight: '10px' }}>
            <Link to="https://doctrina.ai/privacy.html" target="_blank" rel="noopener noreferrer">Privacy policy</Link>
          </li>
          <li>
            <span>|</span>
          </li>
          <li style={{ paddingLeft: '10px' }}>
            <Link to="https://doctrina.ai/terms.html" target="_blank" rel="noopener noreferrer">Terms & Conditions</Link>
          </li>
        </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default ChangePassword;
