import React, { useState, useEffect, useContext } from 'react';
import useAxios from '../utils/UseAxios';
import { Container, Button, Col, Row, Form, Accordion } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../utils/AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DropdownSelect from '../reusable-components/DropdownSelect';
import PaddleCheckout from '../payment/PaddleCheckout';
import Breadcrumb from '../reusable-components/Breadcrumb';
import SocialFooter from './SocialFooter';

const UserProfile = () => {
    const [progress, setProgress] = useState({});
    const [hasPaid, setHasPaid] = useState(false);
    const [role, setRole] = useState('student');
    const [school, setSchool] = useState('');
    const [subjects, setSubjects] = useState([{ value: '', placeholder: 'Mathematics' }]);
    const axiosInstance = useAxios();
    const { logoutUser, user } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserProgress = async () => {
            try {
                const response = await axiosInstance.get('/members/user-progress/');
                setProgress(response.data);
            } catch (error) {
                console.error("Error fetching user progress:", error);
            }
        };

        const fetchPaymentStatus = async () => {
            try {
                const response = await axiosInstance.get("/payments/check-payment/");
                setHasPaid(response.data.status === 'COMPLETED' || response.data.status === 'active');
            } catch (error) {
                console.error("Error fetching payment status:", error);
            }
        };

        const fetchUserProfile = async () => {
            try {
                const response = await axiosInstance.get('/members/get-profile/');
                const { role, school, subjects: fetchedSubjects } = response.data;

                setRole(role || 'student');
                setSchool(school || '');

                if (fetchedSubjects && fetchedSubjects.length > 0) {
                    setSubjects(fetchedSubjects.map(subject => ({
                        value: subject,
                        placeholder: 'Mathematics'
                    })));
                }
            } catch (error) {
                console.error("Error fetching user profile:", error);
            }
        };

        fetchUserProgress();
        fetchPaymentStatus();
        fetchUserProfile();
    }, []);  

    const handleLogout = () => {
        navigate('/login');
        logoutUser();
    };

    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };

    const handleSchoolChange = (event) => {
        setSchool(event.target.value);
    };

    const addSubject = () => {
        setSubjects([...subjects, { value: '', placeholder: 'Mathematics' }]);
    };

    const removeSubject = (index) => {
        setSubjects(subjects.filter((_, i) => i !== index));
    };

    const handleSubjectChange = (index, value) => {
        const newSubjects = [...subjects];
        newSubjects[index].value = value;
        setSubjects(newSubjects);
    };

    const handleSubmit = async () => {
        if (!school.trim()) {
            toast.error('Please enter your school/university name.');
            return;
        }
    
        for (let i = 0; i < subjects.length; i++) {
            if (!subjects[i].value.trim()) {
                toast.error(`Please fill in the class name for class ${i + 1}.`);
                return;
            }
        }
    
        try {
            const subjectValues = subjects.map(subject => subject.value);
            await axiosInstance.post('/members/update-profile/', {
                role,
                school,
                subjects: subjectValues
            });
            toast.success('Profile updated successfully');
        } catch (error) {
            toast.error('Error updating profile.');
            console.error('Error updating profile:', error);
        }
    };

    const handlePaymentSuccess = async (details) => {
        try {
            await axiosInstance.post("/payments/process-payment/", {
                order_id: details.id,
            });
            setHasPaid(true);
        } catch (error) {
            console.error("Error processing payment:", error);
        }
    };

    const customerData = {
        email: user?.email || "default@example.com",
        address: {
            countryCode: "US",
            postalCode: "10021"
        }
    };

    const breadcrumbItems = [
        { label: 'Home', path: '/', active: false },
        { label: 'Profile', path: '/profile', active: true },
    ];

    return (
        <Container style={{ paddingTop: "25px" }}>
            <Row className="align-items-center">
                <Col xs={6}>
                    <Breadcrumb items={breadcrumbItems} />
                </Col>
                <Col xs={6} className="text-right pb-3 logout">
                    <a href="#" onClick={handleLogout} style={{ textAlign: 'right' }}>
                        Logout
                    </a>
                </Col>
            </Row>

            <Container className="left-align" style={{ paddingLeft: "20px", paddingBottom: "20px", paddingTop: "20px" }}>
                <h4>Hi {user.username}</h4>
            </Container>

            <Container className='user-details left-align' style={{ paddingBottom: "25px", paddingTop: "10px", paddingLeft: "20px" }}>
                <Row className="mb-3">
                    <Col md={6}>
                        <Form.Group as={Row} className="align-items-center">
                            <Form.Label column sm={3} style={{ fontWeight: 'bold' }}>Role</Form.Label>
                            <Col sm={9}>
                                <DropdownSelect 
                                    options={[
                                        { label: 'Student', value: 'student' },
                                        { label: 'Teacher', value: 'teacher' }
                                    ]}
                                    value={role}
                                    onChange={handleRoleChange}
                                    style={{ fontWeight: 'normal', padding: '8px' }}
                                />
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={6}>
                        <Form.Group as={Row} className="align-items-center">
                            <Form.Label column sm={3} style={{ fontWeight: 'bold' }}>High School, University or College</Form.Label>
                            <Col sm={9}>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Harvard University"
                                    value={school}
                                    onChange={handleSchoolChange}
                                    style={{ padding: "10px" }}
                                />
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={6}>
                        <Form.Group as={Row} className="align-items-center">
                            <Form.Label column sm={3} style={{ fontWeight: 'bold' }}>Classes</Form.Label>
                            <Col sm={9}>
                                {subjects.map((subject, index) => (
                                    <div key={index} className="d-flex align-items-center mb-2">
                                        <Form.Control
                                            type="text"
                                            value={subject.value}
                                            onChange={(e) => handleSubjectChange(index, e.target.value)}
                                            placeholder={subject.placeholder}
                                            style={{ padding: "10px", flex: "1" }}
                                        />
                                        {subjects.length > 1 && (
                                            <Button 
                                                variant="danger" 
                                                className="ml-2 remove-bulletpoint" 
                                                onClick={() => removeSubject(index)}
                                                style={{ marginLeft: '8px', width: '40px' }}
                                            >
                                                -
                                            </Button>
                                        )}
                                    </div>
                                ))}
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={6}>
                        <div className="d-flex justify-content-between mt-2">
                            <Button 
                                className="button-aligned add-class" 
                                onClick={addSubject}
                                style={{ marginTop: "10px" }}
                            >
                                + Another class
                            </Button>
                            <Button 
                                className="button-aligned submit" 
                                onClick={handleSubmit}
                                style={{ marginTop: "10px" }}
                            >
                                Submit
                            </Button>
                        </div>
                        <span className="text-muted" style={{ fontSize: '0.9em', marginTop: "10px", display: "block" }}>
                            Provide your details so we can tailor our AI models specifically to your learning or teaching needs.
                        </span>
                    </Col>
                </Row>

            </Container>

            <Container className='user-progress left-align' style={{ paddingBottom: "25px", paddingTop: "25px", paddingLeft: "20px" }}>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Progress</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-3">
                                <Col md={4}>
                                    <a className='profile-features-link' href="/quiz"><h5 className='profile-features-link'>Quizzes</h5></a>
                                    <p>{progress.quiz_count || 0}</p>
                                </Col>
                                <Col md={4}>
                                    <a  className='profile-features-link' href="/note"><h5 className='profile-features-link'>Notes</h5></a>
                                    <p>{progress.notes_count || 0}</p>
                                </Col>
                                <Col md={4}>
                                    <a className='profile-features-link' href="/exam"><h5 className='profile-features-link'>Exams</h5></a>
                                    <p>{progress.exams_count || 0}</p>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={4}>
                                    <a className='profile-features-link' href="/essay"><h5 className='profile-features-link'>Essays</h5></a>
                                    <p>{progress.essays_count || 0}</p>
                                </Col>
                                <Col md={4}>
                                    <a className='profile-features-link' href="/summary"><h5 className='profile-features-link'>Summaries</h5></a>
                                    <p>{progress.summaries_count || 0}</p>
                                </Col>
                                <Col md={4}>
                                    <a className='profile-features-link' href="/chat"><h5 className='profile-features-link'>Chat</h5></a>
                                    <p>{progress.chat_count || 0}</p>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Container className='pt-5'>
    <p>Need help? Email us at <a href="mailto:hello@doctrina.ai">hello@doctrina.ai</a></p>
</Container>

            </Container>

            {!hasPaid && (
                <div className="paddle-checkout-container" style={{ width: '100vw', marginLeft: '-50vw', left: '50%', position: 'relative', padding: '0' }}>
                    <PaddleCheckout onSuccess={handlePaymentSuccess} customerData={customerData} />
                </div>
            )}
        </Container>
    );
};

export default UserProfile;







