// import React, { useContext, useEffect, useState } from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import { FaRegPenToSquare, FaCheck, FaGraduationCap, FaFileCircleCheck, FaRocketchat, FaWandMagicSparkles, FaMicrophone } from "react-icons/fa6";
// import AuthContext from "./utils/AuthContext";
// import useAxios from "./utils/UseAxios";
// import Display from "./reusable-components/Display";
// import LoginPage from "./authentication/LoginPage";
// import PaddleCheckout from "./payment/PaddleCheckout";
// import SocialFooter from "./reusable-components/SocialFooter";

// const Home = () => {
//   const { user } = useContext(AuthContext);
//   const loggedIn = user !== null;
//   const [hasPaid, setHasPaid] = useState(false);
//   const axiosInstance = useAxios();

//   useEffect(() => {
//     const fetchPaymentStatus = async () => {
//       if (loggedIn) {
//         try {
//           const response = await axiosInstance.get("/payments/check-payment/");
//           setHasPaid(response.data.status === 'COMPLETED' || response.data.status === 'active');
//         } catch (error) {
//           console.error("Error fetching payment status:", error);
//         }
//       }
//     };
//     fetchPaymentStatus();
//   }, [loggedIn, axiosInstance]);

//   const handlePaymentSuccess = async (details) => {
//     try {
//       await axiosInstance.post("/payments/process-payment/", {
//         order_id: details.id,
//       });
//       setHasPaid(true);
//     } catch (error) {
//       console.error("Error processing payment:", error);
//     }
//   };

//   const customerData = {
//     email: user?.email || "default@example.com",
//     address: {
//       countryCode: "US",
//       postalCode: "10021"
//     }
//   };

//   return (
//     <div className="home-container center pt-5">
//       {loggedIn ? (
//         <>
//           <Container>
//             <Row className="center-align">
//               <Col sm>
//                 <Display
//                   header="Quiz"
//                   description="Create engaging quizzes that explore everything from classical literature to cutting-edge technology."
//                   icon={<FaCheck />}
//                   link="/quiz"
//                 />
//               </Col>
//               <Col sm>
//                 <Display
//                   header="Essay"
//                   description="Compose essays that journey through cultural narratives and scientific discoveries, bringing your unique perspectives to life."
//                   icon={<FaRegPenToSquare />}
//                   link="/essay"
//                 />
//               </Col>

//             </Row>
//             <Row className="center-align">
//               <Col sm>
//                 <Display
//                   header="Exam"
//                   description="Create detailed exams, evaluating your knowledge from complex concepts to pivotal moments in history with clarity and focus."
//                   icon={<FaGraduationCap />}
//                   link="/exam"
//                   locked={!hasPaid}
//                 />
//               </Col>
//               <Col sm>
//                 <Display
//                   header="Notes"
//                   description="Enhance your class notes with deeper insights, transforming basic scribbles into comprehensive guides."
//                   icon={<FaWandMagicSparkles />}
//                   link="/note"
//                   locked={!hasPaid} 
//                 />
//               </Col>
//             </Row>
//             <Row className="center-align">
//               <Col sm>
//                 <Display
//                   header="Summary"
//                   description="Summarize lengthy texts, capturing the essence from complex theories to historical narratives with ease."
//                   icon={<FaFileCircleCheck />}
//                   link="/summary"
//                   locked={!hasPaid}
//                 />
//               </Col>
//               <Col sm>
//                 <Display
//                   header="Chat"
//                   description="Engage with our AI assistant for instant help and answers, whether studying calculus or contemplating existentialism."
//                   icon={<FaRocketchat />}
//                   link="/chat"
//                   locked={!hasPaid}
//                 />
//               </Col>
//             </Row>
//             <Row className="center-align">
//               <Col sm>
//                 <Display
//                   header="Talk"
//                   description="Converse with our AI using your voice, exploring topics from quantum physics to poetry recitation in an interactive and dynamic way."
//                   icon={<FaMicrophone />}
//                   link="/talk"
//                   locked={!hasPaid}
//                 />
//               </Col>
//               <Col sm>
               
//               </Col>
//             </Row>
//           </Container>
//           {!hasPaid && (
//             <div className="checkout-social-container">
//                 <PaddleCheckout onSuccess={handlePaymentSuccess} customerData={customerData} />
//             </div>
//           )}
//         </>
//       ) : (
//         <LoginPage />
//       )}
//     </div>
//   );
// };

// export default Home;


import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaRegPenToSquare, FaCheck, FaGraduationCap, FaFileCircleCheck, FaRocketchat, FaWandMagicSparkles, FaMicrophone } from "react-icons/fa6";
import AuthContext from "./utils/AuthContext";
import Display from "./reusable-components/Display";
import LoginPage from "./authentication/LoginPage";
import SocialFooter from "./reusable-components/SocialFooter";

const Home = () => {
  const { user } = useContext(AuthContext);
  const loggedIn = user !== null;

  return (
    <div className="home-container center pt-5">
      {loggedIn ? (
        <>
          <Container>
            <Row className="center-align">
              <Col sm>
                <Display
                  header="Quiz"
                  description="Create engaging quizzes that explore everything from classical literature to cutting-edge technology."
                  icon={<FaCheck />}
                  link="/quiz"
                />
              </Col>
              <Col sm>
                <Display
                  header="Essay"
                  description="Compose essays that journey through cultural narratives and scientific discoveries, bringing your unique perspectives to life."
                  icon={<FaRegPenToSquare />}
                  link="/essay"
                />
              </Col>
            </Row>
            <Row className="center-align">
              <Col sm>
                <Display
                  header="Exam"
                  description="Create detailed exams, evaluating your knowledge from complex concepts to pivotal moments in history with clarity and focus."
                  icon={<FaGraduationCap />}
                  link="/exam"
                />
              </Col>
              <Col sm>
                <Display
                  header="Notes"
                  description="Enhance your class notes with deeper insights, transforming basic scribbles into comprehensive guides."
                  icon={<FaWandMagicSparkles />}
                  link="/note"
                />
              </Col>
            </Row>
            <Row className="center-align">
              <Col sm>
                <Display
                  header="Summary"
                  description="Summarize lengthy texts, capturing the essence from complex theories to historical narratives with ease."
                  icon={<FaFileCircleCheck />}
                  link="/summary"
                />
              </Col>
              <Col sm>
                <Display
                  header="Chat"
                  description="Engage with our AI assistant for instant help and answers, whether studying calculus or contemplating existentialism."
                  icon={<FaRocketchat />}
                  link="/chat"
                />
              </Col>
            </Row>
            <Row className="center-align">
              <Col sm>
                <Display
                  header="Talk"
                  description="Converse with our AI using your voice, exploring topics from quantum physics to poetry recitation in an interactive and dynamic way."
                  icon={<FaMicrophone />}
                  link="/talk"
                />
              </Col>
              <Col sm></Col>
            </Row>
          </Container>
        </>
      ) : (
        <LoginPage />
      )}
    </div>
  );
};

export default Home;
