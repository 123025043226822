import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useAxios from '../utils/UseAxios';
import { FaUnlock } from "react-icons/fa6";
import AuthContext from '../utils/AuthContext';
import SocialFooter from '../reusable-components/SocialFooter';



const PaddleCheckout = () => {
  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const [email, setEmail] = useState('');
  const { user } = useContext(AuthContext);


  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const response = await axiosInstance.get('/members/get-user-email/');
        setEmail(response.data.email); 
      } catch (error) {
        console.error("Error fetching user email:", error);
      }
    };

    const loadPaddle = () => {
      const script = document.createElement("script");
      script.src = "https://cdn.paddle.com/paddle/v2/paddle.js";
      script.async = true;
      script.onload = () => {
        if (window.Paddle) {
          window.Paddle.Initialize({ 
            token: "live_71486eb5d4691c10c090fb868d2", 
            eventCallback: function(data) {
              console.log('Paddle event data:', data);
              if (data.name === "checkout.completed") {
                window.Paddle.Checkout.close();
                navigate("/thank-you");
              }
            }
          });
        } else {
          console.error("Failed to load Paddle.js");
        }
      };
      script.onerror = () => {
        console.error("Error loading Paddle.js script");
      };
      document.body.appendChild(script);
    };

    const loadLottiePlayer = () => {
      const lottieScript = document.createElement("script");
      lottieScript.src = "https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs";
      lottieScript.type = "module";
      document.body.appendChild(lottieScript);
    };

    loadPaddle();
    loadLottiePlayer();
    fetchUserEmail();
  }, [axiosInstance, navigate]);

  const openCheckout = () => {
    if (window.Paddle) {
      const itemsList = [
        {
          priceId: "pri_01j1mzp3vehsa5vx65tq518y37",
          quantity: 1
        }
      ];
      console.log("Opening Paddle Checkout with items:", itemsList);

      window.Paddle.Checkout.open({
        items: itemsList,
        customer: {
          email: email, 
        },
        successCallback: function() {
          console.log('Checkout success callback triggered.');
          window.Paddle.Checkout.close();
          navigate("/thank-you");
        }
      });
    } else {
      console.error("Paddle is not initialized");
    }
  };

  return (
    <Container fluid className="checkout-container">
      <Row className="justify-content-center">
        <Col xs={12} className="d-flex justify-content-center checkout-col">
          <dotlottie-player 
            src="https://lottie.host/d813c7ae-4a2f-4769-8201-3bf719f17972/4msdZyT6Ci.json" 
            background="transparent" 
            speed="1" 
            style={{ width: '300px', height: 'auto' }} 
            loop 
            autoplay>
          </dotlottie-player>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12}>
          {/* <h3 className="text-center">Unlock Premium</h3> */}
          {/*
          <p className="show-new-text">
          Go premium for $9 and access all features limitless for 
          <span className="strike-through">1 month</span>
          <span className="new-text">a full year.</span> Cancel anytime!
        </p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} className="text-center">
        

  <div className="checkout-icon pt-3 pb-3" onClick={openCheckout}>
    <button 
      style={{ marginRight: '5px', border: 'none', background: 'none' }} 
    >
      Unlock Premium
    </button>
    <FaUnlock />
  </div>
*/}
<p>Doctrina is building its socials presence  </p>
{/* <p>Follow us on Instagram, send us your username, (<b>{user.username}</b>) and we will unlock all the premium features for you. </p> */}
<p>Follow us on Instagram</p>
<SocialFooter /> 


        </Col>
      </Row>
    </Container>
  );
};

export default PaddleCheckout;



